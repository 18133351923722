@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
body { 
    font-family: 'Montserrat', sans-serif;
}

@font-face {
    font-family: 'Archive';
    src: url('../fonts/Archive.eot');
    src: url('../fonts/Archive.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Archive.woff2') format('woff2'),
        url('../fonts/Archive.woff') format('woff'),
        url('../fonts/Archive.ttf') format('truetype'),
        url('../fonts/Archive.svg#Archive') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    scroll-behavior: smooth;
}

a.active,
a:focus,
button:focus,
button.active {
    outline: none;
}
a {
    text-decoration: none;
}
a:focus,
a:hover,
a:active {
    outline: 0;
    box-shadow: none;
}
a:focus,
a:hover {
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 { 
    margin-bottom: 0;
    font-family: 'Montserrat', sans-serif;
}
a {
    text-decoration: none;
    transition: all .5s ease-in-out;
}
a:hover {
    text-decoration: none;
}
ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}
ul li {
    list-style: none;
}
p {
    margin: 0;
}
input:focus,
select:focus,
textarea:focus {
    outline: none;
}
img {
    border-style: none;
    display: inline-block;
    max-width: 100%;
    height: auto;
}
.wrapper{
    overflow: hidden;
}
/*********** HEADER ******/
header{ 
    padding:20px 30px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #121212;
}
.header-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo h2{
    color: #fff;
    font-weight: normal;
    font-family: 'Archive';
    text-transform: uppercase;
    font-size: 17.1111px;
}
/********** BANNER SEC ******/
.banner-sec{ 
    position: relative;
    background: #121212;
    background-size: contain;
    background-position: right center;
    height: 612px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px 0 0 0;
}
/*.banner-sec:after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(107.16deg, rgba(1, 1, 1, 0) 0.51%, #000000 42.99%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(80px);
}*/
.banner-sec .image-holder2{
    position: absolute;
    top: 0;
    right: 0;
}
.banner-sec .text-box{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.banner-sec .text-box h1{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 55px;
    display: inline-block;
    position: relative;
    margin-bottom: 12px;
    max-width: 445px;
    width: 100%;
}
.banner-sec .text-box h1 span{
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    background: #FF3838;
    box-shadow: 0px 0px 19px #FF3838;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    position: absolute;
    top: -43px;
    right: -38px;
    justify-content: center;
}
.banner-sec .text-box p{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    margin-bottom: 30px;
}
.banner-sec .text-box a{
    color: #FFFFFF;
    font-weight: 500;
    background: linear-gradient(84.46deg, #FF3838 49.94%, #D60000 92.89%);
    border-radius: 64px;
    font-size: 20px;
    width: 250px;
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-sec .text-box a img{
    margin-left: 6px;
    margin-top: 3px;
}
.banner-sec .image-holder{
    text-align: center;
    position: relative;
    z-index: 1;
}
.banner-sec .image-holder img{
    z-index: 10;
    position: relative;
}
.round-img {
    position: absolute;
    top: -144px;
    right: -90px;
    z-index: 1;
}
.round-img img{
    max-width: inherit;
}
.banner-sec .image-holder a.richard-btn{
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    background: radial-gradient(91.25% 91.25% at 0% 22.5%, rgba(255, 255, 255, 0.65) 0%, rgba(196, 196, 196, 0) 100%);
    filter: drop-shadow(0px 7px 9px rgba(0, 0, 0, 0.05));
    backdrop-filter: blur(12px);
    border-radius: 21px;
    max-width: 221px;
    width: 100%;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -20px;
    z-index: 10;
}
.banner-sec .image-holder a.richard-btn span{
    background: linear-gradient(84.46deg, #FF3838 49.94%, #D60000 92.89%);
    border-radius: 64px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    justify-content: center;
}
/********** ABOUT SEC *******/
.about-sec{
    background-color: #121212;
    padding: 30px 0;
    position: relative;
    z-index: 10;
    overflow: hidden;
}
.about-waves{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}
.title{
    position: relative;
}
.title:after{
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    background: linear-gradient(270deg, #121212 17.78%, #FF3838 77.31%);
    width: 100%;
    height: 2px;
}
.title h2{
    display: inline-block;
    color: #FF3838;
    font-weight: 600;
    font-size: 20px;
    background-color: #121212;
    position: relative;
    z-index: 1;
    padding-right: 30px;
}
.about-inner{
    max-width: 890px;
    width: 100%;
    position: relative;
    margin: 130px auto 85px; 
}
.about-inner:after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #121212 24.1%, rgba(18, 18, 18, 0) 96.14%);
    width: 195px;
    height: 185px;
    z-index: 100;
}
.about-inner:before{
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: linear-gradient(90deg, #121212 22.17%, rgba(18, 18, 18, 0) 96.14%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 221px;
    height: 185px;
    z-index: 100;
}
.about-inner .swiper-container { 
    padding: 20px 0; 
}
.about-sec .swiper-slide-active .about-box{
    background: #FF3838;
    box-shadow: 0px 0px 19px #FF3838;
    transition: all 0.5s ease-out; 
}
.about-sec .swiper-slide-prev .about-box {
    margin: 0;
}
.about-sec .swiper-slide-next .about-box {
    margin: 0 0 0 auto;
}
.about-box{
    text-align: center; 
    max-width: 179px;
    width: 100%;
    height: 179px;
    margin: 0 auto;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s ease-out;
    align-items: center;
}
.about-box h3{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    max-width: 140px;
    width: 100%;
    margin: 13px auto 0;
}
.about-sec .swiper-pagination {
    position: static;
    margin: 50px 0 0 0;
}
.about-sec .swiper-pagination-bullet{
    border-radius: 38px;
    border: 0.5px solid #FFFFFF;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.5s ease-in-out;
    margin: 0 -25px;
    opacity: 1;
}
.about-sec .swiper-pagination-bullet-active{
    background-color: #fff;
}
.about-sec .swiper-button-next:after, .about-sec .swiper-button-prev:after{
    display: none;
}
.about-sec .swiper-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 345px;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}
.about-sec .swiper-button-next, .about-sec .swiper-button-prev{
    position: static;
    width: auto;
    height: auto;
}
.round-ball{
    background: #FF3838;
    width: 34px;
    height: 34px;
    border-radius: 50%; 
    display: flex;
    align-items: center;
    z-index: 1000;
    position: absolute;
    justify-content: center;
}
.round-ball.round-ball-first{
    top: -57px;
    left: 77px;
}
.round-ball.round-ball-second {
    bottom: 25px;
    left: -60px;
    width: 22px;
    height: 22px;
}
.round-ball.round-ball-thrd {
    top: 0;
    right: -110px;
    width: 56px;
    height: 56px;
}
/********* AUTO FOLLOWING ********/
.auto-following-sec{
    background: #FF3838  url(../images/auto-following-bg.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 30px 0 147px;
    position: relative;
}
.auto-following-sec .title {
    margin-bottom: 70px;
}
.auto-following-sec .title:after{
    background: linear-gradient(270deg , #FF3838 17.78%, #121212 77.31%);
    width: 83%;
    right: 0;
    left: auto;
}
.auto-following-sec .title h2{
    color: #121212;
    background-color: transparent;
}
.auto-following-sec .text-box{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.auto-following-sec .text-box h3{
    color: #FFFFFF;
    font-weight: bold;
    font-size: 35px;
    line-height: 43px;
}
.round-ball-white{
    background: #FFFFFF;
    width: 26px;
    height: 26px;
    border-radius: 50%; 
    bottom: 32px;
    right: 87px;
    position: absolute;
}
.ball-white-first {
    width: 56px;
    height: 56px;
    top: -12px;
    left: 120px;
}
.ball-white-second{
    width: 14px;
    height: 14px;
    bottom: 125px;
    right: -18px;
    left: auto;
}
.auto-following-sec .image-holder{
    padding-left: 20px;
}
/************ PRICE SEC *******/
.price-sec{
    background: #121212;
    padding: 34px 0;
    position: relative;
}
.heading{
    max-width: 953px;
    width: 100%;
    margin: 0 auto 20px;
    position: relative;
    z-index: 10;
}
.heading h3{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 8px;
}
.heading span{
    color: #FF3838;
    font-weight: 500;
    font-size: 15px;
    display: block;
    text-transform: uppercase;
}
.price-inner{
    max-width: 953px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 10;
}
.round-ball-price3 {
    width: 22px;
    height: 22px;
    right: 0;
    left: auto;
    top: -46px;
}
.price-box{
    border: 2px solid #FFFFFF;
    border-radius: 15px; 
    margin: 15px 0;
    background-color: #121212;
}
.discount{
    height: 43px;
}
.discount p{
    letter-spacing: 0.195em;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    text-align: center; 
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;  
    position: relative;
}
.discount p:after{
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    background-color: #fff;
    width: 100%;
    height: 1px;
}
.discount p span{
    background-color: #121212;
    display: inline-block;
    padding: 0 6px;
    position: relative;
    z-index: 1;
}
.price-head{
    background: #272727;
    border-radius: 0px 0px 50px 50px;
    text-align: center;
    padding: 22px 0 19px;
    margin: 0 -2px;
}
.price-head h4{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    text-transform: uppercase;

}
.price-head span{
    color: #636363;
    letter-spacing: 0.195em;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    min-height: 15px;
}
.price-head h3{ 
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    margin-top: 10px;
    font-size: 33.3333px;
}
.price-head h3 svg {
    margin-right: 5px;
}
.price-head p{
    color: #FFFFFF;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
}
.price-body{
    padding: 20px 0;
}
.price-body ul {
    padding: 0 7px;
    margin-bottom: 23px;
}
.price-body ul li{
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 2px;
    line-height: 22px;
    color: #fff;
    padding-left: 13px;
    position: relative;
}
.price-body ul li:after{
    position: absolute;
    content: "";
    left: 0;
    top: 9px;
    background: #FF3838;
    border-radius: 50%; 
    width: 6px;
    height: 6px;
}
.price-body a,
.price-body button {
    background: #FF3838;
    box-shadow: 0px 0px 19px #FF3838;
    width: 90px;
    height: 90px;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    color: #FFFFFF;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
    border: 0;
    cursor: pointer;
}
.price-box.price-active{
    border-color: #FF3838;
}
.price-box.price-active .discount p span{
    color: #FF3838;
}
.price-box.price-active .discount p:after{
    background-color:  #FF3838;
}
.price-box.price-active .price-head h3{
    color:  #FF3838;
}
.price-box.price-active .price-head p{
    color:  #FF3838;
}
.price-inner .text-box {
    text-align: center;
    margin: 30px 0 0 0;
}
.price-inner .text-box p{
    color: #787878;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
}
.price-inner .text-box a{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 20px;
    background: linear-gradient(84.46deg, #FF3838 49.94%, #D60000 92.89%);
    border-radius: 64px;
    width: 180px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid transparent;
}
.round-ball-price1 {
    top: 50%;
    transform: translate(0, -50%);
    left: 16px;
    z-index: 0;
}
.round-ball-price2{
    bottom: 69px;
    right: 83px;
    width: 56px;
    height: 56px;
}
.price-waves{
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
}
/******** TESTIMONIAL ******/
.testimonial-sec{
    background:  url(../images/testimonial-bg.png) no-repeat;
    background-size: cover;
    background-position: center center; 
    position: relative;
    padding: 31px 0 100px;
    position: relative;
}
.testimonial-sec .title h2{
    color: #121212;
    background-color: transparent;
}
.testimonial-sec .title:after{
    background: linear-gradient(270deg, #FFFFFF 17.78%, #121212 77.31%);
    width: 89%;
    right: 0;
    left: auto;
}
.testimonial-inner {
    max-width: 945px;
    width: 100%;
    margin: 165px auto 0;
}
.testimonial-box{
    display: flex;
    align-items: center;
}
.testimonial-box .image-holder{
    max-width: 77px;
    width: 100%;
}
.testimonial-box .text-box{
    flex: 1;
    width: 100%;
    padding-left: 15px;
    display: none;
    padding-right: 25px;
}
.testimonial-box .text-box h3{
    color: #121212;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;
}
.testimonial-box .text-box h3 span{
    font-weight: 500;
    font-size: 12px;
    color: #121212;
}
.testimonial-box .text-box p{
    font-weight: normal;
    font-size: 15px;
    line-height: 124%;
    color: #121212;
}
.testimonial-inner .swiper-wrapper{
    align-items: center;
}
.testimonial-inner .swiper-slide-active {
    width: 607px !important;
    margin-left: -180px;
}
.testimonial-inner .swiper-slide-active .testimonial-box{
    background: radial-gradient(77.76% 79.52% at 93% 72.63%, #FF3838 0%, rgba(250, 250, 250, 0) 100%);
    filter: drop-shadow(0px 26px 25px rgba(0, 0, 0, 0.05));
    backdrop-filter: blur(32px); 
    border-radius: 50px;
    border: 1px solid #FF3838;
}
.testimonial-inner .swiper-slide-active .testimonial-box .image-holder img {
    width: 100%;
    border-radius: 50px 50px 0 50px;
}
.testimonial-inner .swiper-slide-active .testimonial-box  .image-holder{
    max-width: 185px;
    width: 100%;
}
.testimonial-inner .swiper-slide-active .testimonial-box  .image-holder img{
    width: 100%;
}
.testimonial-inner .swiper-slide-active .testimonial-box .text-box{
    display: block;
}
.testimonial-inner .swiper-pagination{
    position: static;
    margin: 83px 0 0 0;
}
.testimonial-inner .swiper-pagination-bullet{
    border-radius: 38px;
    border: 0.5px solid #121212;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    opacity: 1;
    background-color: transparent;
    margin: 0 25px;
    transition: all 0.5s ease-in-out;
}
.testimonial-inner .swiper-pagination-bullet-active{
    background: #121212;
    transition: all 0.5s ease-in-out;
}
.testimonial-inner .swiper-button-next:after, .testimonial-inner .swiper-button-prev:after{
    display: none;
}
.testimonial-inner .swiper-button-next img, .testimonial-inner .swiper-button-prev img {
    filter: brightness(0.5%);
}
.testimonial-inner .swiper-button-next, .testimonial-inner .swiper-button-prev{
    position: static; 
    width: auto;
    height: auto;
    margin-top: 0;
}
.testimonial-inner .swiper-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 59%;
    transform: translate(0, -50%);
    width: 100%;
    z-index: 100;
    left: 0;
}
.testimonial-round-ball-first {
    width: 56px;
    height: 56px;
    left: 97px;
    bottom: 30px;
}
.testimonial-round-ball-second {
    width: 14px;
    height: 14px;
    right: 193px;
    top: 80px;
    left: auto;
}
/******* FOOTER ******/
footer {
    background: #131313;
    padding: 15px 0;
}
.footer-inner{
    display: flex;
    align-items: center;
}
.footer-logo h2 {
    color: #D9D9D9;
    font-weight: normal;
    font-family: 'Archive';
    font-size: 17.1111px;
    text-transform: uppercase;
    max-width: 80px;
    text-align: center;
    width: 100%;
    line-height: 17.11px;
}
.footer-copy{
    flex: 1;
    width: 100%;
    text-align: center;
}
.footer-copy .telegram-link {
    color: #D9D9D9;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-copy .telegram-link img{
    margin-left: 20px;
}
/******** SIDEBAR ******/
.sidebar{
    background: #FF3838;
    position: fixed;
    right: -100%;
    top: 0;
    width: 582px;
    height: 100%;
    z-index: 1001;
    transition: all 0.5s ease-in-out;
    padding: 20px;
}
.sidebar.active{
    right: 0;
    transition: all 0.5s ease-in-out;
}
.sidebar-body{
    height: calc(100% - 48px);
    display: flex;
    align-items: center;
}
.sidebar-body ul{
    width: 100%;
}
.sidebar-body ul li{
    margin-bottom: 35px;
}
.sidebar-body ul li:last-child{
    margin-bottom: 0;
}
.sidebar-body ul li a{
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 45px;
    display: flex;
    align-items: center;
}
.sidebar-body ul li a:hover{
    color: #D2D2D2;
}
.sidebar-body ul li a:hover img.simple-state{
    display: none;
}
.sidebar-body ul li a:hover img.hover-state{
    display: block;
}
.sidebar-body ul li a img{
    display: none;
}
.sidebar-body ul li a span{
    margin-left: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 25px;
    width: 100%;
}
.sidebar-body ul li a img.hover-state{
    display: none;
}
.sidebar-footer .telegram-link {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
}
.sidebar-footer .telegram-link img{
    margin-left: 30px;
}
.review-date {
    opacity: 0;
}


.sidebar-header {
    display: flex;
}

.sidebar-header a {
    margin-right: auto;
}


#sidebar-close-menu {
    outline: 0;
    border: 0;
    background: none;
    cursor: pointer;
}